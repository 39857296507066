const reviews = [
    {
      id: 1,
      name: "Afseen Khandaker",
      job: "SYEP Intern, 2024",      
      text: "I really liked all the projects that we did and how we were shown how to code them. I learned a lot of coding and learned how to use vscode. I also learned how to code in CSS, HTML and JavaScript.     I really enjoyed coding and debugging the code that we wrote. I like how we could collaborate with each other and easily ask for help. Thank you!! ",
    },
    {
      id: 2,
      name: "Arnav Sivakumar",
      job: "SYEP Intern, 2024",      
      text: "My experience has been great, as I got to work on fun projects that helped me grow as a software developer. The support I received throughout the process allowed me to develop my skills. I learned a lot about coding, including HTML, CSS, and JavaScript, and gained hands-on experience designing web pages and simple apps. Choicest Fruit also taught me about the planning, ethics, and other aspects involved in app development beyond just coding. This broader understanding will help me succeed in future projects. I especially appreciated how my supervisor gave us ample time to work on our projects at our own pace, which allowed me to challenge myself and improve my apps. It was a productive and enjoyable experience.",
    },
    {
      name: "Brian Kevin Steele",
      job: "SYEP Intern, 2024",   
      text:"My experience so far with the program is at first it was kinda rough but as time went on things started to get easier and less challenging. Some things I have learned are how to put codes on github, and now I know the basics of computer tech. I'm really glad I stayed with this program. What I enjoyed the most about the program was being able to work some days at home and also come into the office. It was also cool because I got to work with one of my friends from school and we got to see each other.  ",
    },
    {
      name: "Brijnandan Saranu",
      job: "SYEP Intern, 2024",    
      text:"My experience has been very positive thanks to the helpful instructors who provided the support I needed to succeed. They taught me how to use technology ethically, keep my computer secure, and create an inclusive, diverse workplace. I also learned how to ensure my technology respects all cultures and practices. The technical skills I gained include working with JavaScript and HTML, styling projects, adding buttons, images, and audio, and pushing code to GitHub. I learned how to turn my code into a website and commit changes. Debugging was another key skill I picked up. What I enjoyed most was connecting with new people who share my interests, making friends from different schools through Choicest Fruit. This experience was unique, as we all had the chance to meet through the internship, something we wouldn't have done otherwise.",
    },
    {
      id: 4,
      name: "Ellen Avery Scales",
      job: "SYEP Intern, 2024",     
      text: "I have had a good experience working at Choicest Fruit. Aaron and Golriz have been very helpful with teaching the lessons and assisting with coding. Before the internship I knew a little bit of code but I have been able to become much more proficient in multiple languages after the help from their program. It has been extremely rewarding to be able to see a project from start to finnish and make multiple of my own websites.",
    },
    {
      name: "Emil Corazon Trevino",
      job: "SYEP Intern, 2024",    
      text:"Overall, the program was very enjoyable. The activities were well-organized and easy to follow, and I appreciated how the program was self-contained with minimal stress, making it easy to stay on top of projects. Although I don't enjoy being online, the in-person days were much more enjoyable than the virtual ones. The extended weekends with four-day workweeks and three-day weekends were a nice bonus. With my previous coding experience, I really appreciated the deeper dive into JavaScript, HTML, and CSS. I especially enjoyed creating the website, despite some frustrations, as well as the process of building the text-based video game, particularly during the storyboarding phase. However, my favorite projects were the personalized website and Math Buddy, both of which I enjoyed working on and debugging the most.",
  },
  {
      name: "Ishani Sadagopan Jeyaram Malismitha",
      job: "SYEP Intern, 2024",    
      text: "This program has really broadened my understanding of the world and the tech industry in general. This program was a great experience in terms of getting early real life experience in programming and working on a project together as a team or by yourself. It helps grow your leadership and collaborative skills along with being organized. I’ve learned how important values and beliefs are for yourself and others. I’ve also learned how to program web applications using HTML, CSS, and Javascript. Being able to bring a few sentences of words to life and do something amazing that could help others. Bringing it to life along with other people during its development is even more enjoyable than working by myself.",
  },
  {
      name: "Jacob Lamont Brandon",
      job: "SYEP Intern, 2024",    
      text:" My experience with the program has been a wonderful experience for me. Learning about coding has been fun and making code has also been really helpful because that opened a window of opportunities for me in the future with my career path. I have learned how to code and how to make websites,and apps with different languages like for example JavaScript and html. I was taught a great amount of coding and the time spent on it was worthwhile.This is something that I would consider doing again. Something that I really enjoyed about the program is the idea and the understatement of how websites are made and the process that it takes to make one. I enjoyed the people that are here they come from different backgrounds than me so it is nice to understand different peoples ideas about how they would do something for their websites and their code. ",
  },
  {
      name: "Javaughn Carter Lamar",
      job: "SYEP Intern, 2024",    
      text:"The experience I had at Choicest Fruit had been wonderful,  everyone's nice at work and what we are learning is useful. From Choicest Fruit I have learned some of the languages of coding and learning how to use them in VScode. What I enjoyed most was the learning experience of learning the coding languages. Thank you, sincerely JaVaughn.",
  },
  {
      name: "Jaydon Tinnie Prince",
      job: "SYEP Intern, 2024",    
      text:"It's been good and interesting to be here. I've learnt that things aren't what they seem like I thought making a website would be very hard and take months but it turns out to not be that bad. I enjoyed the coding and making my own website.",
  },
  {
      name: "Kavi Rahman",
      job: "SYEP Intern, 2024",     
      text:"I had a very helpful experience with the program and I enjoyed learning about software development. I learned the basics of programming from two very skilled software developers. I enjoyed the ease into a new skill. This was my first time ever coding but because of this program, I learned very stress-free.  ",
  },
  {
      name: "Martha Audrey Pruitt",
      job: "SYEP Intern, 2024",     
      text:"My experience with the Choicest Fruit program has been positive. I've learned how to code proficiently in HTML and CSS, and have started to learn how to use JavaScript, I've also had the opportunity to learn about the guidelines that surround ethical tech development and use. What I enjoyed most about the program was the coding activities and collaborative spaces that allowed me to learn and grow as a young software developer.",
  },
  {
      name: "Nathalia Torres",
      job: "SYEP Intern, 2024",    
      text:"I had a great experience with this program because I have been able to learn more about programming which is something I hope to do in the future. I have learned how to collaborate with my peers to figure out problems in the code. Most importantly I now have a basic understanding of how to use html, css, and javascript.  I mostly enjoyed getting to talk and get to know my peers. I found it fun when we worked together to fix a bug in one of our codes. My favorite project out of all the ones that we worked on was My Website project as it had many different components to it.",
  },
  {
      name: "Parinaz Enayatpour",
      job: "SYEP Intern, 2024",     
      text:"My experience at Choicest Fruit has been wholly positive. I have gained invaluable experience and I had fun along the way. We analyzed issues in the technological field and worked out solutions to address those issues through different courses and through brainstorming in developing an app. We also followed along in writing codes for websites and put the teachings into practice. Working through issues in downloading different software and extensions was difficult but rewarding. Throughout this internship, I have learned the basics in the HTML, CSS, and JavaScript coding languages. I have also learned that brainstorming and listening to feedback are by far the most important parts of creating a product. The most enjoyable part of the program for me was applying what I learned about HTML and CSS. I also really enjoyed thinking about the topics within the two courses and trying to come up with the best solution possible to any of the given issues or questions.",
  },
  ];
  
  export default reviews;
  