import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.png";
import Particle from "../Particle";
import Home2 from "./Home2";

import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

function Home() {

  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    navigate("/project"); // This will navigate to the "/another-page" route
  };

  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
        <Container className="home-content">
          <Row>
            <Col md={7} className="home-header">
              <h1 style={{ paddingBottom: 15 }} className="heading">
              <img
                src={homeLogo}
                alt="home pic"
                className="img-fluid"
                style={{ maxHeight: "700px" }}
              />Choicest Fruit<span className="small-r-symbol">®</span>{" "}              
              </h1>

              <h1 className="heading-name">
                <i>Knowledge and Technology
                  <strong className="main-name"> Serving the Welfare of Humanity</strong></i>
                
              </h1>
             
              
            </Col>
          
          
          </Row>
        </Container>
        
      </Container>
      <Button onClick={handleClick}     
        >Join Our Programs</Button>
      <Home2 />
    </section>
  );
}

export default Home;
