import React, { useState } from 'react';
import './video-gallery.css';

const VideoGallery= () => {
  const [activeVideo, setActiveVideo] = useState(0);

  const videos = [
    { src: "/videos/drone.mp4", title: "01. drone modelling and animation" },
    { src: "/videos/atom.mp4", title: "02. modelling and animation an atom" },
    { src: "/videos/fluid.mp4", title: "03. fluid simulation" },
    { src: "/videos/water.mp4", title: "04. realistic water simulation" },
    { src: "/videos/chair.mp4", title: "05. cloth filter" },
    { src: "/videos/loop.mp4", title: "06. physics looping animation" },
    { src: "/videos/satisfyingLoop.mp4", title: "07. satisfying loop animation" },
    { src: "/videos/game-asset.mp4", title: "08. modelling and animating a game asset" },
    { src: "/videos/critter.mp4", title: "09. hair animation" },
  ];

  const handleVideoClick = (index) => {
    setActiveVideo(index);
  };

  return (
    <div className="model-gallery">
     
      <div>
        
      </div>
      <div className="container-video">
        <div className="main-video">
          <div className="video">
            <video src={videos[activeVideo].src} controls muted autoPlay></video>
            <h3 className="title">{videos[activeVideo].title}</h3>
          </div>
        </div>

        <div className="video-list">
          {videos.map((video, index) => (
            <div
              key={index}
              className={`vid ${index === activeVideo ? 'active' : ''}`}
              onClick={() => handleVideoClick(index)}
            >
              <video src={video.src} muted></video>
              <h3 className="title">{video.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
