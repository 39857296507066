import React from 'react'
import styled from 'styled-components'
import Card2 from './Card2'
import { InnerLayout } from './Layout'
import check from '../../Assets/img/check.svg';
import checkDisabled from '../../Assets/img/check-disabled.svg';

const DetailCard = () => {
   return (
      <PaymentStyled id='pricing'>
         <InnerLayout>
            <div data-aos='fade-down-right'>
               <h3 className="small-heading">
               Exceptional training, <span>at  the right price</span>
               </h3>
               <p className="c-para"> With an instructor by your side, you will feel confident and supported </p>
            </div>
            <div className='card-con'>
               <Card2
                  account={'Learning Objectives'}
                  text={'Perfect for individuals seeking new skills or enhancing their portfolio'}
                  check={check}
                  checkDis={checkDisabled}
                  text1={'Learners will understand the basics of working in a 3D design environment and know the different basic tools in Blender and navigate Blender’s interface.'}
                  text2={'Learn how to create visual effects, art, 3D-printed models, motion graphics, interactive 3D applications, 3D modelling, UV mapping, texturing, digital drawing, rigging and skinning, fluid and smoke simulation, particle simulation, soft body simulation, sculpting, animation , rendering, motion graphics, video editing, Python scripting, and compositing.'}
                  text3={'Learners will know how to use primitive shapes and how to manipulate their geometry to create more complex shapes.'}
                  text4={'Participants will learn how to build any object with the use of simple shapes and a few modifications of the topology.The participant will learn how to create a material and how to assign it to one and more objects.'}
                  text5={'Learners will understand how to place a light(s) and a camera in their scene in order to establish a shot, and will know what rendering is and what are the necessary properties to keep in mind when you export an image.'}
                  text6={''}
               />
            </div>
         </InnerLayout>
      </PaymentStyled>
   )
}

const PaymentStyled = styled.section`
 .card-con {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-top: 4rem; /* Adjust top padding */
    
    /* Optional: Use max-width to control the card's maximum size */
    max-width: 1200px; 
    margin: 0 auto;
    width: 100%; /* Ensure it takes full width of the container */
    
    /* Optional: If you want to increase the width of the cards themselves */
    .card2 {
      width: 100%; /* Adjust the width of individual cards */
      max-width: 100%; /* You can set a specific width like 80% or more */
    }

    @media screen and (max-width: 689px) {
      flex-direction: column; /* Stack content on mobile */
    }
  }

  p {
    text-align: center;
  }
`

export default DetailCard
