import React from "react";
import { Col, Row } from "react-bootstrap";

import shiftup2 from "../../Assets/Projects/shiftup2.gif";
import travis_county from "../../Assets/Projects/Travis_County.png";
import austin_city from "../../Assets/Projects/Austin_City.png";
import san_marcos from "../../Assets/Projects/San_Marcos_Library.png";
import crimson_ark from "../../Assets/Projects/crimson_ark.png";

import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
      <img src={travis_county} alt="about" className="img-fluid" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={austin_city} alt="about" className="img-fluid" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={shiftup2} alt="about" className="img-fluid" />      
      </Col>
      <Col xs={4} md={2} className="tech-icons">
      <img src={san_marcos} alt="about" className="img-fluid" />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        Crimson Ark Studio
        <img src={crimson_ark} alt="about" className="img-fluid" />
      </Col>
    </Row>
  );
}

export default Toolstack;
