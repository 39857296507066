import React from "react";
import { Col, Row } from "react-bootstrap";


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={1} md={2} className="tech-icons">      
        We believe equity is achieved by valuing all voices, fostering inclusion,
        and empowering individuals to contribute to community well-being.

      </Col>
      <Col xs={4} md={2} className="tech-icons">
        We create supportive spaces for youth to belong, connect personal growth
        with social change, and inspire collaboration to tackle shared challenges.
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        We are dedicated to fairness and equality, working to dismantle
        systemic barriers and ensure everyone has the opportunity to thrive.
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        We believe meaningful change occurs when we create equitable opportunities
        for individuals while also addressing the systemic challenges that impact our world.
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        We value collaboration as a skill that empowers individuals to work effectively
        across cultures, navigate team dynamics, and foster mutual learning and growth.


      </Col>
     
    </Row>
  );
}

export default Techstack;
