import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import TeamCard from "./TeamCards";
import Particle from "../Particle";
import gloria from "../../Assets/Projects/gloria.jpg";
import galindo from "../../Assets/Projects/galindo.jpg";
import golriz from "../../Assets/Projects/golriz.jpg";
import aaron from "../../Assets/Projects/aaron.jpg";

function Team() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Team </strong>
        </h1>
        <p style={{ color: "white" }}>
        We are engaged in empowering communities by fostering positive relationships through appropriate tech development.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <TeamCard
              imgPath={golriz}
              isBlog={false}
              title="Golriz Sedaghat"
              text="Co-Founder"
              description="Golriz is an Electrical Engineer with over 10 years of wide-ranging experience from the field of biomedical to semiconductor industry. She is dedicated to finding solutions for complex problems through a collaborative and interdisciplinary approach."
               text1="golriz@choicestfruit.com"
              ghLink="https://www.linkedin.com/in/golriz-sedaghat-25a635105/"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <TeamCard
              imgPath={aaron}
              isBlog={false}
              title="Aaron Enyetu"
              text="Co-Founder"
              description="Aaron is a Full-Stack Software Engineer and Creative Developer, committed to community-driven innovation  with expertise in front-end and back-end development. He is passionate about empowering communities through responsible tech development. "
              text1="aaron@choicestfruit.com"
              ghLink="https://www.linkedin.com/in/aaron-enyetu/"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <TeamCard
              imgPath={galindo}
              isBlog={false}
              title="Galindo R Aliyo"
              text="Administrative Director"
              description="Galindo’s background is in legal practice and research. She has led teams and has driven initiatives focused on justice and innovation. She has also served as the head of research & strategy, overseeing research efforts and strategic direction for management consulting firms."
              text1="galindo@choicestfruit.com"
              ghLink="https://www.linkedin.com/in/galindo-aliyo-b716a594/"
              demoLink="#"              
            />
          </Col>

          <Col md={4} className="project-card">
            <TeamCard
              imgPath={gloria}
              isBlog={false}
              title="Gloria Ludigo"
              text="Operations Manager"
              description="Gloria Ludigo is an Advocate with extensive experience in law, regulatory compliance, legal advisory, project management, and technology. She believes that successful projects begin with a well-prepared team and has contributed to workspaces that deliver quality, timely results, leading to the successful achievement of project goals."
              text1="gloria@choicestfruit.com"
              ghLink="https://www.linkedin.com/in/gloria-ludigo-b2ab41140/"
              demoLink="#"
            />
          </Col>

          
        </Row>
      </Container>
    </Container>
  );
}

export default Team;
