import React from 'react'
import styled from 'styled-components'
import Card2 from './Card2'
import { InnerLayout } from './Layout'
import check from '../../Assets/img/check.svg';
import checkDisabled from '../../Assets/img/check-disabled.svg';

const DetailCard = () => {
   return (
      <PaymentStyled id='pricing'>
         <InnerLayout>
            <div data-aos='fade-down-right'>
               <h3 className="small-heading">
               Exceptional training, <span>at  the right price</span>
               </h3>
               <p className="c-para"> With an instructor by your side, you will feel confident and supported </p>
            </div>
            <div className='card-con'>
               <Card2
                  account={'Learning Objectives'}
                  text={'Perfect for individuals seeking new skills or enhancing their portfolio'}
                  check={check}
                  checkDis={checkDisabled}
                  text1={'In this training, the learner will go beyond the code to understand what is really important in web app development by incorporating an ethical framework to the design approach.'}
                  text2={'Participants will learn to identify the needs of the users, include users in the development process and to structure content and make informed design decisions based on the needs of the users before writing a single line of code.'}
                  text3={'Learners will build empathy and an understanding of target audience needs, will recognize the benefits of approaching design from the perspective of the user and the importance of accessibility, biases, inclusivity and cultural'}
                  text4={'Participants will master the languages, frameworks and libraries of the web: HTML, CSS, JavaScript, Git, Node, API, and React. This path is great for front-end or back-end engineers. They will also be introduced to Deployment platforms such as GitHub.io and Netlify.'}
                  text5={'Learners will create software applications that are designed to be accessed and used through web browsers. They will create wireframes, prototypes and UI/UX mockups to provides a great user experience, and this includes a clean, intuitive interface, easy navigation, and fast load times.'}
                  text6={''}
               />
            </div>
         </InnerLayout>
      </PaymentStyled>
   )
}

const PaymentStyled = styled.section`
 .card-con {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-top: 4rem; /* Adjust top padding */
    
    /* Optional: Use max-width to control the card's maximum size */
    max-width: 1200px; 
    margin: 0 auto;
    width: 100%; /* Ensure it takes full width of the container */
    
    /* Optional: If you want to increase the width of the cards themselves */
    .card2 {
      width: 100%; /* Adjust the width of individual cards */
      max-width: 100%; /* You can set a specific width like 80% or more */
    }

    @media screen and (max-width: 689px) {
      flex-direction: column; /* Stack content on mobile */
    }
  }

  p {
    text-align: center;
  }
`

export default DetailCard
