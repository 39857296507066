import Globe from 'react-globe.gl';
import Button from "react-bootstrap/Button";
import { useState } from 'react';

import Techstack from "./Techstack";
// import Toolstack from "./Toolstack";

import ethAppImage from '../../Assets/EthiApp.png';
import mathBuddyImage from '../../Assets/MathBuddy2.png';
import q4VirtueImage from '../../Assets/Q4Virtue.png';
import ageCalcImage from '../../Assets/AgeCalc.png';
import alarmClockImage from '../../Assets/AlarmClock.png';
import VideoGallery from './VideoGallery';

const AboutLab = () => {
  const [isInteracting, setIsInteracting] = useState(false);

  // Handle mouse events to detect interaction with the globe
  const handleMouseDown = () => setIsInteracting(true);
  const handleMouseUp = () => setIsInteracting(false);
  const handleMouseMove = () => {
    if (isInteracting) return; // Only trigger on dragging
    setIsInteracting(false); // Reset on mouse movement
  };

  return (
    <section className="c-space my-20 min-h-screen overflow-x-hidden" id="about">
      <div className="col-span-1 xl:row-span-4">
        <div className="grid-container">
          {/* Globe Container with Tooltip on Hover */}
          <div className="relative flex justify-center items-center group">
            {/* Tooltip on hover (Transparent, hidden when interacting with the globe) */}
            <div
              className={`absolute z-10 text-white bg-black bg-opacity-50 p-2 rounded-lg transform -translate-y-20 scale-0 group-hover:scale-100 group-hover:translate-y-0 transition-all duration-300 ${
                isInteracting ? 'hidden' : ''
              }`}
            >
              <p className="text-sm">Pan or Spin the 3D Globe</p>
            </div>

            {/* Globe Component */}
            <div
              className="rounded-3xl w-full sm:h-[326px] h-fit flex justify-center items-center"
              onMouseDown={handleMouseDown}
              onMouseUp={handleMouseUp}
              onMouseMove={handleMouseMove}
            >
              <Globe
                height={326}
                width={326}
                backgroundColor="rgba(0, 0, 0, 0)"
                backgroundImageOpacity={0.5}
                showAtmosphere
                showGraticules
                globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
                bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
                labelsData={[{ lat: 40, lng: -100, text: 'Rjieka, Croatia', color: 'white', size: 60 }]}
              />
            </div>
          </div>

          {/* Heading Between Globe and Cards */}
          <div className="text-center mt-12">
            <h2 className="text-3xl font-semibold text-gray-800">Explore Our Lab</h2>
            <p className="text-lg text-gray-600 mt-4">
              Dive into our lab and discover how participants are bringing ideas to life.
            </p>
          </div>

          {/* Cards Section */}
          <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-8 space-y-8 sm:space-y-0 mt-10">
            {/* Left Card (Transparent and Animated Tilt) */}
            <div className="card bg-transparent opacity-75 rounded-lg shadow-lg p-6 w-full sm:max-w-[400px] transition-transform transform hover:rotate-3 hover:scale-105 hover:shadow-2xl">
              <h1 className="text-xl font-bold mb-4">Hands-On Learning Experience</h1>
              <p className="grid-headtext mb-4">
              Our lab activities are designed to inspire creativity, foster collaboration, and build practical skills for participants' future careers. From coding apps and building robots to creating 3D models, each activity offers an engaging learning experience that is both educational and fun.
              </p>             
            </div>

            {/* Middle Card (Opaque and Animated Tilt) */}
            <div className="card bg-white rounded-lg shadow-lg p-6 w-full sm:max-w-[600px] transition-transform transform hover:rotate-3 hover:scale-105 hover:shadow-2xl">
              <h1 className="text-xl font-bold mb-4">Innovation. Creativity. Purpose</h1>
              <p className="grid-headtext mb-4">
              Our lab is a dynamic environment where ideas come to life. Through specialized programs, we expose our participants to the latest technologies and trends, preparing them for career opportunities in tech and entrepreneurship. 
              </p>
              <Button
    variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
    className="mt-4" // Margin top
    onClick={() => window.open('https://external-link-1.com', '_blank')}
    style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
  >
    Get Started
  </Button>
            </div>

            {/* Right Card (Transparent and Animated Tilt) */}
            <div className="card bg-transparent opacity-75 rounded-lg shadow-lg p-6 w-full sm:max-w-[400px] transition-transform transform hover:rotate-3 hover:scale-105 hover:shadow-2xl">
              <h1 className="text-xl font-bold mb-4">What You'll Gain</h1>
              <p className="grid-headtext mb-4">Cutting-edge Training, Hands-on Experience, Career and Entrepreneurial Skills, Collaboration.</p>
            </div>
          </div>

          
          <h1 className="project-heading">
          Web-App <strong className="purple">Projects</strong>
        </h1>

          {/* Smaller Project Cards Below */}
          <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-8 space-y-8 sm:space-y-0 mt-10">
            {/* Small Project Card 1 */}
            <div
              className="card bg-white rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl bg-cover bg-center"
              style={{ backgroundImage: `url(${ethAppImage})` }}
            >
            <h1 className="text-lg font-semibold mb-3">Ethical Tech App</h1>
            {/* <p className="text-sm text-gray-600">An app that presents developers with scenarios and dilemmas on the ethical implications of technology.</p> */}
            <Button
              variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
              className="mt-4" // Margin top
              onClick={() => window.open('https://ishanisjm.github.io/EthicalTechApp/', '_blank')}
              style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
            >
              View Project
            </Button>
            </div>

            {/* Small Project Card 2 */}
            <div className="card bg-white rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl bg-cover bg-center"
               style={{ backgroundImage: `url(${mathBuddyImage})` }}
            >
              <h1 className="text-lg font-semibold mb-3">Math Buddy</h1>
              <p className="text-sm text-gray-600"></p>
              <Button
              variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
              className="mt-4" // Margin top
              onClick={() => window.open('https://ishanisjm.github.io/mathBuddy/', '_blank')}
              style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
              >
                  View Project
              </Button>
            </div>

            {/* Small Project Card 3 */}
            <div className="card bg-white rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl bg-cover bg-center"
            style={{ backgroundImage: `url(${q4VirtueImage})` }}
            >
              <h1 className="text-lg font-semibold mb-3">Quest for Virtue Game</h1>
              <p className="text-sm text-gray-600"></p>
              <Button
              variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
              className="mt-4" // Margin top
              onClick={() => window.open('https://universaltruth01.github.io/QuestForVirtue/', '_blank')}
              style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
              >
                  View Project
              </Button>
            
            </div>

             {/* Small Project Card 4 */}
            <div className="card bg-white rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl bg-cover bg-center "
             style={{ backgroundImage: `url(${ageCalcImage})` }}
            >
              <h1 className="text-lg font-semibold mb-3">Age Calculator</h1>
              <p className="text-sm text-gray-600"></p>
              <Button
              variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
              className="mt-4" // Margin top
              onClick={() => window.open('https://ellenscales19.github.io/ageCalculator', '_blank')}
              style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
              >
                  View Project
              </Button>
            
            </div>          

             {/* Small Project Card 6 */}
            <div className="card bg-white rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl bg-cover bg-center"
             style={{ backgroundImage: `url(${alarmClockImage})` }}
            >
              <h1 className="text-lg font-semibold mb-3">Alarm Clock</h1>
              <p className="text-sm text-gray-600"></p>
              <Button
              variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
              className="mt-4" // Margin top
              onClick={() => window.open('https://nabilkalenga.github.io/Alarm-Clock/', '_blank')}
              style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
              >
                  View Project
              </Button>
           
            </div>
          </div>






          <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-8 space-y-8 sm:space-y-0 mt-10">
            {/* Small Project Card 7 */}
            <div className="card bg-black rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl">
              <h1 className="text-lg text-gray-600 font-semibold mb-3">Personal Portfolio</h1>
              <p className="text-sm text-blue-400">
              <a href="https://ishanisjm.github.io/myWebsite/" target="_blank" rel="noopener noreferrer"  className="text-blue-400 hover:text-blue-600">
                  Ishani Malismitha
              </a>
              </p>
            </div>

            {/* Small Project Card 8 */}
            <div className="card bg-black rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl">
              <h1 className="text-lg  text-gray-600 font-semibold mb-3">Personal Portfolio</h1>
              <p className="text-sm text-blue-400">
              <a href="https://nathaliat07.github.io/My-website/" target="_blank" rel="noopener noreferrer"  className="text-blue-400 hover:text-blue-600">
                  Nathalia Torres
              </a>
              </p>
            </div>

            {/* Small Project Card 9*/}
            <div className="card bg-black rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl">
              <h1 className="text-lg  text-gray-600 font-semibold mb-3">Personal Portfolio</h1>
              <p className="text-sm text-blue-400">
              <a href="https://javaughn-223.github.io/My-website/" target="_blank" rel="noopener noreferrer"  className="text-blue-400 hover:text-blue-600">
                  Javaughn Lamar
               </a>
              </p>
            </div>

         

          </div>


          <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-8 space-y-8 sm:space-y-0 mt-10">
                {/* Small Project Card 4 */}
                <div className="card bg-black rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl">
              <h1 className="text-lg  text-gray-600 font-semibold mb-3">Personal Portfolio</h1>
              <p className="text-sm text-blue-400">
              <a href="https://emil-trevino.github.io/myWebsite/" target="_blank" rel="noopener noreferrer"  className="text-blue-400 hover:text-blue-600">
                  Emil Trevino
              </a>
              </p>
            </div>

             {/* Small Project Card 5 */}
             <div className="card bg-black rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl">
              <h1 className="text-lg  text-gray-600 font-semibold mb-3">Personal Portfolio</h1>
              <p className="text-sm text-blue-400">
              <a href="https://afseenk.github.io/website/" target="_blank" rel="noopener noreferrer"  className="text-blue-400 hover:text-blue-600">
                  Afseen Khandaker
              </a>
              </p>
            </div>

             {/* Small Project Card 6 */}
             <div className="card bg-black rounded-lg shadow-lg p-4 w-full sm:max-w-[300px] transition-transform transform hover:scale-105 hover:shadow-xl">
              <h1 className="text-lg  text-gray-600 font-semibold mb-3">Personal Portfolio</h1>
              <p className="text-sm text-blue-400">
              <a href="https://ellenscales19.github.io/Website/" target="_blank" rel="noopener noreferrer"  className="text-blue-400 hover:text-blue-600">
                    Ellen Scales
              </a>
              </p>
            </div>
          </div>



          <h1 className="project-heading">
          3D Web <strong className="purple">Projects </strong>
        </h1>

        <Techstack />

        <h1 className="project-heading">
          <strong className="purple">3D Modeling & Animation</strong> Projects
        </h1>
        <VideoGallery/>

        </div>
      </div>
    </section>
  );
};

export default AboutLab;
