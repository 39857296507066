import React from "react";
import Card from "react-bootstrap/Card";


function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p className="about-text">
            At Choicest Fruit®, our vision is to <span className="purple">empower young people</span> in Hays, Travis, Williamson and Caldwell Counties, Texas,
            to become active participants in the development of technology that promotes positive relationships
            with themselves, others, and the world. We aim to equip individuals with the awareness, values,
            and skills needed to contribute to just and thriving societies through responsible tech practices.
            By integrating local experiences and diverse perspectives into the tech development process,
            we ensure that technology is designed, distributed, and used in ways that reflect the unique needs and values of our communities.
          </p>

          <p className="about-text">
            Our mission goes beyond providing <span className="purple">access to knowledge</span>; we strive to create an environment where
            everyone can actively engage in technology development, making it meaningful and impactful.
            We believe that conversations around the ethical use and development of technology should
            start early, fostering curiosity and critical thinking in young people. Whether their interest
            in technology emerges naturally or through gradual exposure, we cater our approach to the
            unique developmental paths and aspirations of each individual.
          </p>

          <p className="about-text">
            A core principle of Choicest Fruit® is infusing <span className="purple">human values</span> into the tech development process.
            We teach young people to consider the broader impacts of technology at every stage,
            encouraging them to move beyond simply replicating market-driven trends. By emphasizing
            their agency in creating grassroots tech solutions, we help them develop the skills to
            create technology that meets the needs of their families, peers, and local communities.
          </p>

          <p style={{ color: "rgb(4,99,112)" }}>
            “… In this day the choicest fruit of the tree of knowledge is that which serveth the welfare of humanity and safeguardeth its interests."{" "}
          </p>
          <footer className="blockquote-footer"></footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
