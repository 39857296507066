import React from 'react'
import styled from 'styled-components'
import Card2 from './Card2'
import { InnerLayout } from './Layout'
import check from '../../Assets/img/check.svg';
import checkDisabled from '../../Assets/img/check-disabled.svg';

const DetailCard = () => {
   return (
      <PaymentStyled id='pricing'>
         <InnerLayout>
            <div data-aos='fade-down-right'>
               <h3 className="small-heading">
               Exceptional training, <span>at  the right price</span>
               </h3>
               <p className="c-para"> With an instructor by your side, you will feel confident and supported </p>
            </div>
            <div className='card-con'>
               <Card2
                  account={'Learning Objectives'}
                  text={'Perfect for individuals seeking new skills or enhancing their portfolio'}
                  check={check}
                  checkDis={checkDisabled}
                  text1={'Learn about fundamentals of electronics and electrical engineering including voltage, current, resistance, Ohm’s law, circuits and electric components.'}
                  text2={'Learn about how use electronic components to design electronic circuits.'}
                  text3={'Learn about the engineering design process.'}
                  text4={'Get hands-on experience by working individually and in groups to design and develop both simple and complex projects.'}
                  text5={'After completing this program, the participant is equipped with the knowledge and skills to work on more complex electrical and robotic projects.'}
                  text6={''}
               />
            </div>
         </InnerLayout>
      </PaymentStyled>
   )
}

const PaymentStyled = styled.section`
 .card-con {
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding-top: 4rem; /* Adjust top padding */
    
    /* Optional: Use max-width to control the card's maximum size */
    max-width: 1200px; 
    margin: 0 auto;
    width: 100%; /* Ensure it takes full width of the container */
    
    /* Optional: If you want to increase the width of the cards themselves */
    .card2 {
      width: 100%; /* Adjust the width of individual cards */
      max-width: 100%; /* You can set a specific width like 80% or more */
    }

    @media screen and (max-width: 689px) {
      flex-direction: column; /* Stack content on mobile */
    }
  }

  p {
    text-align: center;
  }
`

export default DetailCard
