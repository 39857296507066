import React from "react";
import { Container, Row} from "react-bootstrap";
import Particle from "../Particle";
import styled from 'styled-components'

import ContactForm from "./ContactForm";

function Contact() {
  return (
    <Container fluid className="project-section">
   <ParticleWrapper>
                <Particle />
            </ParticleWrapper>
      <Container>
        <h1 className="project-heading">
          Let's <strong className="purple">Collaborate </strong>
              </h1>          
        <p style={{ color: "#047180" }}>
        We'd Love to Hear From You!
              </p>
              <p style={{ color: "black" }}>
        Email Us: info@choicestfruit.com
        </p>
              <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                  

                  <ContactForm/>
          

          
        </Row>
      </Container>
    </Container>
  )
}

const ParticleWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;  // Ensures it covers the full viewport height
    z-index: -1;     // Adjust z-index to be above background, below content
    opacity: 0.8;   // Optional: Add opacity to make particles more visible
`

export default Contact;
