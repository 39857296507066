import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import Team from "./components/Team/Team";
import WebPricing from "./components/WebPricing/PricingCard1";
import RoboticsPricing from "./components/RoboticsPricing/RoboticsCard"
import ThreeDWebPricing from "./components/ThreeDWebPricing/ThreeDWebCard"
import ThreeDModelingPricing from "./components/ThreeDModelingPricing/ThreeDModelingCard"
import JusticeTechPricing from "./components/JusticeTechPricing/JusticeTechCard"
import AppropTechPricing from "./components/AppropTechPricing/AppropTechCard"

import Impact from "./components/Impact/Impact";
import Contact from "./components/Contact/Contact";
import Lab from "./components/Lab/AboutLab";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/project" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/impact" element={<Impact />} />
          <Route path="/team" element={<Team/>} />
          <Route path="/contact" element={<Contact />} />          
          <Route path="/web-pricing" element={<WebPricing />} />
          <Route path="/robotics-pricing" element={<RoboticsPricing />} />
          <Route path="/three-d-web-pricing" element={<ThreeDWebPricing />} />
          <Route path="/three-d-modeling-pricing" element={<ThreeDModelingPricing />} />
          <Route path="/justice-tech-pricing" element={<JusticeTechPricing />} />
          <Route path="/approp-tech-pricing" element={<AppropTechPricing />} />
          <Route path="/lab" element={<Lab />} />
          <Route path="/resume" element={<Resume />} />
          <Route path="*" element={<Navigate to="/"/>} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
