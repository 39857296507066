import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";


function ProjectCards5(props) {


  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    navigate("/justice-tech-pricing"); // This will navigate to the "/another-page" route
  };





  return (
    <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Button onClick={handleClick} variant="primary" href={props.ghLink} >
    
          {props.isBlog ? "Blog" : "More Details"}
        </Button>
        {"\n"}
        {"\n"}

       
      </Card.Body>
    </Card>
  );
}
export default ProjectCards5;
