import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/logo8.png";
import Tilt from "react-parallax-tilt";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";


function Home2() {

  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    navigate("/project"); // This will navigate to the "/another-page" route
  };


  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              EMPOWERING. <span className="purple"> INNOVATING. </span> EDUCATING
            </h1>
            <p className="home-about-body">
              Choicest Fruit<span className="small-r-symbol">®</span> is a forward-thinking social enterprise driven by a passion for harnessing
              the power of technology to solve real-world social challenges. We are committed to transforming lives by offering targeted
              training programs in cutting-edge fields such as software development, electronics, and robotics engineering. Our approach
              combines innovation with purpose, empowering individuals with the skills they need to thrive in today’s tech-driven world.
              Through our tailored solutions and hands-on learning experiences, we aim to create lasting social impact by equipping
              communities with the tools to build a brighter, more sustainable future.
              <br />
              <br />We offer training for youth ages 14-24, enhancing their
              <i>
                <b className="purple"> creativity, hands on skills, career awareness, entrepreneurial skills, exposure and readiness by offering the most relevant training and industry-based work experience. </b>
              </i>
              <br />
              <br />
      
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>JOIN OUR PROGRAMS</h1>
            <p>
              Get trained in as little as <span className="purple">2 months </span>
            </p>
            <Button onClick={handleClick}>Get Started</Button>
    
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
