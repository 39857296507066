import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Impact2 from "./Impact2";
import Testimonials from "./Testimonials";
import reviews from "./data";


import syepMain from "../../Assets/syepMain.png";
import cf_1 from "../../Assets/cf_1.png";
import cf_e3 from "../../Assets/cf_e3.png";
import cf_2 from "../../Assets/cf_2.png";
import cf_3 from "../../Assets/cf_e1.png";
import cf_4 from "../../Assets/cf_e2.png";
import gradSYEP from "../../Assets/gradSYEP.png";



// Import slick-carousel styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const sliderImages = [
  { src: syepMain, alt: "syep_image" },
  { src: cf_1, alt: "Image 1" },
  { src: cf_e3, alt: "Image 2" },
  { src: cf_2, alt: "Image 3" },
  { src: cf_3, alt: "Image 4" },
  { src: cf_4, alt: "Image 5" },
  { src: gradSYEP, alt: "Image 6" },



  // Add more images as needed

  ];
console.log(sliderImages)


function Impact() {

  // Settings for the Slick slider
  const settings = {
    dots: true, // Show dots for navigation
    infinite: true, // Infinite loop
    speed: 500, // Transition speed in ms
    slidesToShow: 1, // Show one image at a time
    slidesToScroll: 1, // Scroll one image at a time
    autoplay: true, // Enable autoplay
    autoplaySpeed: 2000, // Time between slides in ms
  };



  return (
    <section>
      <Container fluid className="home-section" id="home">
        <Particle />
             {/* Second Container with the Image Slider */}
        <Container className="image-slider-container"
            style={{
              // height: "400px",
              paddingTop: "80px",
              background: "transparent", // makes the container background transparent
              position: "relative"
            }}
        
        >
        <Row>
          <Col md={12}>
            <Slider {...settings}>
              {sliderImages.map((image, index) => (
                <div key={index}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="img-fluid"
                    style={{ objectFit: "cover", height: "60%", width: "100%" }}
                  />
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
      </Container>
          <Impact2 />

                                        
                    <div className="testimonials">
          <Testimonials reviews={reviews}/>              
          </div>
             

          
           
    </section>
  );
}

export default Impact;
