import React from 'react'
import {FaQuoteLeft, FaQuoteRight} from 'react-icons/fa';


const Card = (props) => {
    let review = props.review;
  return (
    <div className='flex flex-col md:relative'>
   
      
      <div className='text-center mt-5 sm:mt-7'>
        <p className='tracking-wider font-bold text-xl sm:text-2xl capitalize'>{review.name}</p>
        <p className='text-sky-700 uppercase text-xs sm:text-sm'>{review.job}</p>
      </div>


      <div className='text-sky-800 mx-auto mt-3 sm:mt-5'>
        <FaQuoteLeft/>
      </div>

      <div className='text-center mt-3 sm:mt-4 text-slate-500 text-sm sm:text-base'>
        {review.text}
      </div>

      <div  className='text-sky-800 mx-auto mt-3 sm:mt-5'>
        <FaQuoteRight/>
      </div>
    </div>
  )
}

export default Card
