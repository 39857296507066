import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import ProjectCard2 from "./ProjectCards2";
import ProjectCard3 from "./ProjectCards3";
import ProjectCard4 from "./ProjectCards4";
import ProjectCard5 from "./ProjectCards5";
import ProjectCard6 from "./ProjectCards6";
import Particle from "../Particle";
import robotics from "../../Assets/Projects/robotics.png";
import justice from "../../Assets/Projects/justice.png";
import threedmod from "../../Assets/Projects/3dmodAnim.png";
import webdev from "../../Assets/Projects/webdev.png";
import appropriate from "../../Assets/Projects/ATdev.png";
import threedweb from "../../Assets/Projects/3dweb.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Our <strong className="purple">Programs </strong>
        </h1>
        <p style={{ color: "white" }}>
          No prior experience is required.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={webdev}
              isBlog={false}
              title="Web/App Development"
              description="This training aims to develop creative thinkers who can assess their community's tech needs and collaborate to create functional, user-centered websites and apps. It emphasizes balancing design and usability, while integrating ethical principles and coding best practices to build effective and responsible web solutions."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard2
              imgPath={robotics}
              isBlog={false}
              title="Electronics and Robotics"
              description="Learn about the electrical and robotics engineering field and what it takes to become a electrical and robotics engineer, including the required skills and experience. This is a cutting-edge, multidisciplinary field, learners will be responsible for designing, assembling, testing, and maintaining robots. Participants will understand mechanics, electronics, sensory feedback systems, and how these complex machines operate."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard3
              imgPath={threedweb}
              isBlog={false}
              title="Build & Design Interactive 3D Websites"
              description="Integrating 3D graphics into web development goes beyond aesthetics, enhancing user engagement, storytelling, realism, and product visualization. It opens new interactive possibilities, making it an exciting tool for developers, designers, and enthusiasts alike. Learn how to create immersive, interactive websites—like portfolios, product pages, and game sites—using tools like Three.js, R3F, WebGL, and Blender."
              ghLink="#"
              demoLink="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard4
              imgPath={threedmod}
              isBlog={false}
              title="3D Modeling and Animation"
              description="Learn how to work with a 3D creation suite to create your own 3D environments, create game assets, model characters, create animations for your objects & characters, create interactive content and visual effects appropriate for web applications and designs for advertising such as logos, and product designs. Learn to create graphical environments for technical solutions such as architecture, engineering and simulations."
              ghLink="#"
              demoLink="#"              
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard5
              imgPath={justice}
              isBlog={false}
              title="Justice and Technology"
              description="This self-paced course, designed for individuals ages 14 and up, aims to empower tech users to become developers within a modern, effective tech development system. It also helps current tech developers enhance their ability to support their communities through technical assistance and knowledge application."
              ghLink="#"
              demoLink="#" 
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard6
              imgPath={appropriate}
              isBlog={false}
              title="Appropriate Technology Development"
              description="This is a self-paced course intended for anyone, ages 14 and above. The course is directed towards users of technology and proposes to develop their capacity to become tech developers within a new and more appropriate system of tech development process as well as become effective participants in research and in the application and the socialization of knowledge. The course is also directed towards the tech developers in order to increase their capacity to offer technical assistance to their communities."
              ghLink="#"
              demoLink="#" 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
