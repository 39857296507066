import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";

import physicsImage from '../../Assets/physics.png';
import portalImage from '../../Assets/portal.png';
import portfolioImage from '../../Assets/portfolio.png';
import gameAnimImage from '../../Assets/gameAnim.png';
import threeDAnimImage from '../../Assets/threeDAnim.png';


function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={1} md={2} className="tech-icons bg-cover bg-center" style={{ backgroundImage: `url(${physicsImage})`, minHeight: "250px", color: "white", padding: "20px"  }}>  
      Real-Time Animations  
      <Button
    variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
    className="mt-4 custom-button" // Margin Bottom
    onClick={() => window.open('https://aaronenyetu.github.io/physics_threejs_and_rapier/', '_blank')}
    style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
  >
    View Project
  </Button>  
      </Col>
      <Col xs={1} md={2} className="tech-icons bg-cover bg-center" style={{ backgroundImage: `url(${portalImage})`, minHeight: "250px", color: "white", padding: "20px"  }}>  
      3D-Web Portal Scene  
      <Button
    variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
    className="mt-4 custom-button" // Margin Bottom
    onClick={() => window.open('https://3d-portal-scene.vercel.app/', '_blank')}
    style={{ width: 'auto', padding: '8px 16px'}} // Adjust the size of the button
  >
    View Project
  </Button>  
      </Col>
      <Col xs={1} md={2} className="tech-icons bg-cover bg-center" style={{ backgroundImage: `url(${portfolioImage})`, minHeight: "250px", color: "white", padding: "20px"  }}>  
      3D Portfolio Website 
      <Button
    variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
    className="mt-4 custom-button" // Margin Bottom
    onClick={() => window.open('https://3d-portfolio-landing-page.vercel.app/', '_blank')}
    style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
  >
    View Project
  </Button>  
      </Col>
      <Col xs={1} md={2} className="tech-icons bg-cover bg-center" style={{ backgroundImage: `url(${gameAnimImage})`, minHeight: "250px", color: "white", padding: "20px"  }}>  
      3D Web Game Assets  
      <Button
    variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
    className="mt-4 custom-button" // Margin top
    onClick={() => window.open('https://animated-game-asset.vercel.app/', '_blank')}
    style={{ width: 'auto', padding: '8px 16px' }} // Adjust the size of the button
  >
    View Project
  </Button>  
      </Col>
      <Col xs={1} md={2} className="tech-icons bg-cover bg-center" style={{ backgroundImage: `url(${threeDAnimImage})`, minHeight: "250px", color: "black", padding: "20px"  }}>  
      3D Web Physics Animations
      <Button
    variant="primary" // Optional: to apply bootstrap's predefined style (you can use other variants like 'secondary')
    className="mt-4 custom-button" // Margin top
    onClick={() => window.open('https://satisfying-loop-threejs.vercel.app/', '_blank')}
    style={{ width: 'auto', padding: '8px 16px',  marginBottom: '20px' }} // Adjust the size of the button
  >
    View Project
  </Button>  
      </Col>
     
    </Row>
  );
}

export default Techstack;
