import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/syep14.png";
import Tilt from "react-parallax-tilt";


import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";


function Impact2() {

  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    navigate("/resume"); // This will navigate to the "/another-page" route
  };




    const videoId = "5mPU-mIQgoQ"; 
return (
    <Container fluid className="home-about-section" id="about">
    <Container>
        <Row>
        <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
            SUMMER YOUTH <span className="purple"> EMPLOYMENT </span> PROGRAM
            </h1>
            <p className="home-about-body">
                        Choicest Fruit® partnered with <b className="purple">Travis County</b> and the <b className="purple">City of Austin</b> to host a 7-week
                        internship program for 20 youth, ages 14-17, as part of the <i>
                        <b className="purple">2024 Work-Based Learning/Summer
                        Youth Employment Program</b></i>. The internship aimed to build career awareness, exposure, and
                        readiness by providing valuable training and hands-on work experience in the field of
                        Responsible/Ethical Technology Development. The program brought together a diverse
                        group of young people from various backgrounds, allowing them to explore and gain
                        experience in ethical tech design and the development of web apps, while progressing
                        through different stages of their educational journeys.
            <br />
                        <br />Throughout the internship, participants took part in technology courses,
                        ethical design thinking, and the responsible development of apps. They worked on
                        collaborative projects, both remotely and on-site, to design EdTech applications
                        and ethical tools for engineering and design workflows using in-demand software technologies.
                        The program focused on developing the interns' capacity to contribute to technology development,
                        empowering them to think critically and ethically about tech's impact on their lives and communities.
                        Choicest Fruit® structured the program around building intellectual, creative, and moral capacity,
                        helping the interns identify their community's tech needs and collaborate to design solutions with a
                        sense of purpose and service.
           
            <br />
            <br />
            
          </p>
          <Button onClick={handleClick}>Youth Declaration</Button>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>SUMMER INTERNSHIP PROGRAM</h1>
          <Container>    
        {/* Responsive Video */}
        <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', maxWidth: '100%', marginBottom: '20px' }}>
                                <iframe
                                    title="YouTube video"
                                    src={`https://www.youtube.com/embed/${videoId}`}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
      </div>
    </Container>
           
          </Col>
            </Row>

            
                <Col md={12} className="home-testimonial">
                <h1>INTERN TESTIMONIALS</h1>
                </Col>
          
            


      </Container>
    </Container>
  );
}
export default Impact2;
