import React from 'react'
import styled from 'styled-components'
import Card from './Card'
import { InnerLayout } from './Layout'
import card from '../../Assets/img/creditcard.png';
import check from '../../Assets/img/check.svg';
import checkDisabled from '../../Assets/img/check-disabled.svg';
import DetailCard from './DetailCard';
import { Container } from "react-bootstrap";
import Particle from "../Particle";

import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

const ThreeDModelingCard = () => {

  const navigate = useNavigate(); // Initialize navigate

  const handleClick = () => {
    navigate("/project"); // This will navigate to the "/another-page" route
  };
    
    return (
        <Container >
      <ParticleWrapper>
                <Particle />
            </ParticleWrapper>
      <PaymentStyled id='pricing'>
         <InnerLayout>
            <div data-aos='fade-down-right'>
               <h3 className="small-heading">
                  3D Modeling and Animation Training
               </h3>
               <p className="c-para">Start with registration and proceed with payments. </p>
            </div>
            <Button onClick={handleClick}>View Programs</Button>
            <div className='card-con'>
               <Card
                  account={'Registration'}                 
                  text={'A smooth registration and enrollment process.'}
                  button={'Register Here'}
                  card={card}
                  link="https://docs.google.com/forms/d/e/1FAIpQLSc49Ind-eStUwPQ6hM08YfwIozNRR6ffXkGf7iqCNIbY-jJmA/viewform"
                //   active={active}
                //   inactive={inactive}
                  check={check}
                  checkDis={checkDisabled}
                  text1={'Eligibility Confirmation: Verification that the applicant meets age and program criteria. '}
                  text2={'Program Details Email: further information about the schedule, expectations, and any prerequisites is provided'}
                  text3={'Confirmation Email: A confirmation email to acknowledge receipt of your application is sent. '}
                  text4={'Acceptance Email: A final email with details like orientation dates, required materials, and the program start date is sent. '}
                  text5={'Onboarding Packet: Pre-training orientation and preparation is provided '}
                  text6={''}
                //   text7={'Exchange 24 currencies'}
                //   text8={'Multi-user access'}
               />

               <Card
                  account={'Payment'}
                  amount={'$270'}
                  text={'A seamless and user-friendly payment integration.'}
                button={'Pay Here'}
                link="https://www.paypal.com/ncp/payment/WFHWQWYE6DXD8"
                  card={card}
                //   active={active}
                //   inactive={inactive}
                  check={check}
                  checkDis={checkDisabled}
                  text1={'Training Length: 2 months.'}
                  text2={'Experience: No programming experience needed.'}
                  text3={'Certification: Certificate awarded upon completion.'}
                  text4={'Training Setting: In-Person|Remote|Hybrid.'}
                  text5={'Software Requirements: No paid software required.'}
                  text6={'Training Requirements: Access to internet.'}
                //   text7={'Exchange 24 currencies'}
                //   text8={'Multi-user access'}
                 />

           </div>
         </InnerLayout>
            </PaymentStyled>
            <Container>
                <DetailCard/>
                </Container>
            </Container>
      
   )
}

const ParticleWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;  // Place it behind all other content
`;

const PaymentStyled = styled.section`
 .card-con {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem; /* Reduce the gap between the cards */
    padding-top: 4rem; /* Reduce top padding */
    
    /* Optional: Use max-width to control the card's maximum size */
    max-width: 1200px;
    margin: 0 auto;
        
    @media screen and (max-width: 689px) {
      grid-template-columns: 1fr; /* Stack cards on mobile */
    }
  }

  p {
    text-align: center;
  }
`



export default ThreeDModelingCard
